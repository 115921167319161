import './App.scss'

import ReactCanvasTxt from 'react-canvas-txt'

const entities = {
  dtxo: {
    name: 'DTXO',
    imprint: 'impd.svg',
  },
  nxef: {
    name: 'NXEF',
    imprint: 'impn.svg',
  },
}

const thisEntity =
  (window.location.host.match(/dtxo/) && entities.dtxo) || entities.nxef

function App() {
  return (
    <div className='fixed w-100 h-100 do-font'>
      <div className='fixed w-100 h-100 flex justify-center items-center'>
        <svg viewBox='0 0 700 700' className='w-25-ns w-100 h-100'>
          <symbol id='s-text'>
            <text
              dominantBaseline='middle'
              textAnchor='middle'
              x='50%'
              y='50%'
              className='f-headline do-font'
            >
              {thisEntity.name}
            </text>
          </symbol>
          <use className='text' xlinkHref='#s-text'></use>
          <use className='text' xlinkHref='#s-text'></use>
          <use className='text' xlinkHref='#s-text'></use>
          <use className='text' xlinkHref='#s-text'></use>
          <use className='text' xlinkHref='#s-text'></use>
        </svg>
      </div>
      <div className='fixed w-100 h-100 flex justify-end items-end'>
        <img src={thisEntity.imprint} className='mr2-ns mr1 mv5-ns mv3 o-70' />
      </div>
    </div>
  )
}

export default App
